<template>
  <div class="checkout-qec-methods">
    <div class="edit-correo">
      <p class="title-checkot-qec">Correo electrónico</p>
      <span class="edit" @click="$emit('editEmail')">Editar</span>
    </div>
    <input
      type="email"
      class="input-form-global payment-input mb-4"
      disabled
      :value="dataQEC.cart.client.email"
    />
    <p class="title-checkot-qec">Seleccione el método de pago</p>
    <div role="tablist">
      <b-card no-body class="card-select-payment-method">
        <b-card-header
          class="card-header-payment-method"
          :style="
            paymentMethods['pse'] && !paymentMethods['pse'].blocked
              ? ''
              : 'opacity: .4'
          "
          :class="
            optionSelect == 3 ? 'card-header-payment-method-selected' : ''
          "
          header-tag="header"
          role="tab"
        >
          <div
            v-b-toggle.accordion-3
            @click="
              paymentMethods['pse'] && !paymentMethods['pse'].blocked
                ? optionSelect == 3
                  ? (optionSelect = 0)
                  : (optionSelect = 3)
                : ''
            "
          >
            <div
              class="content-payment-method"
              :class="optionSelect == 3 ? 'content-payment-method-select' : ''"
            >
              <span
                class="payment-method-icon"
                :class="optionSelect == 3 ? 'payment-method-icon-selected' : ''"
              >
                <img src="@/assets/icons/tipoPago/icon-pse.svg" alt="" />
              </span>
              <div>
                <p class="payment-method-text mb-0">
                  PSE
                </p>
                <span class="payment-method-subtext">
                  Transferencia electrónica
                </span>
              </div>
              <span class="payment-method-arrow">
                <img
                  v-if="optionSelect == 3"
                  src="@/assets/icons/arrow-method-payment-left.svg"
                  alt="flecha izquierda"/>
                <img
                  v-else
                  src="@/assets/icons/arrow-method-payment-right.svg"
                  alt="flecha derecha"
              /></span>
            </div>
          </div>
        </b-card-header>
        <b-collapse
          v-if="paymentMethods['pse'] && !paymentMethods['pse'].blocked"
          id="accordion-3"
          accordion="payment-methods"
          role="tabpanel"
          class="content-payment-methods"
        >
          <div class="container-payment-method">
            <payment-p-s-e
              v-if="paymentMethods['pse']"
              :data-payment="paymentMethods['pse']"
              :pse="!paymentMethods['pse'].blocked"
            />
          </div>
        </b-collapse>
      </b-card>
      <b-card no-body class="card-select-payment-method">
        <b-card-header
          class="card-header-payment-method"
          :style="
            paymentMethods['efecty'] && !paymentMethods['efecty']?.blocked
              ? ''
              : 'opacity: .4'
          "
          :class="
            optionSelect == 4 ? 'card-header-payment-method-selected' : ''
          "
          header-tag="header"
          role="tab"
        >
          <div
            v-b-toggle.accordion-4
            @click="
              !paymentMethods['efecty'].blocked
                ? optionSelect == 4
                  ? (optionSelect = 0)
                  : (optionSelect = 4)
                : ''
            "
          >
            <div
              class="content-payment-method"
              :class="optionSelect == 4 ? 'content-payment-method-select' : ''"
            >
              <span
                class="payment-method-icon"
                :class="optionSelect == 4 ? 'payment-method-icon-selected' : ''"
              >
                <img src="@/assets/icons/tipoPago/icon-efecty.svg" alt="" />
              </span>
              <div>
                <p class="payment-method-text mb-0">
                  Efecty
                </p>
                <span class="payment-method-subtext">
                  Paga en efectivo en puntos efecty
                </span>
              </div>
              <span class="payment-method-arrow">
                <img
                  v-if="optionSelect == 4"
                  src="@/assets/icons/arrow-method-payment-left.svg"
                  alt="flecha izquierda"/>
                <img
                  v-else
                  src="@/assets/icons/arrow-method-payment-right.svg"
                  alt="flecha derecha"
              /></span>
            </div>
          </div>
        </b-card-header>
        <b-collapse
          v-if="paymentMethods['efecty'] && !paymentMethods['efecty']?.blocked"
          id="accordion-4"
          accordion="payment-methods"
          role="tabpanel"
          class="content-payment-methods"
        >
          <div class="container-payment-method">
            <payment-efecty
              v-if="paymentMethods['efecty']"
              :efecty="!paymentMethods['efecty'].blocked"
              :data-payment="paymentMethods['efecty']"
              :cash="parseInt(dataQEC.cart.total)"
            />
          </div>
        </b-collapse>
      </b-card>
      <b-card no-body class="card-select-payment-method">
        <b-card-header
          class="card-header-payment-method"
          :style="
            paymentMethods['tarjeta'] && !paymentMethods['tarjeta'].blocked
              ? ''
              : 'opacity: .4'
          "
          :class="
            optionSelect == 6 ? 'card-header-payment-method-selected' : ''
          "
          header-tag="header"
          role="tab"
        >
          <div v-b-toggle.accordion-6 @click="selectCreditCard()">
            <div
              class="content-payment-method"
              :class="optionSelect == 6 ? 'content-payment-method-select' : ''"
            >
              <span
                class="payment-method-icon"
                :class="optionSelect == 6 ? 'payment-method-icon-selected' : ''"
              >
                <img src="@/assets/icons/tipoPago/icon-tarjeta.svg" alt="" />
              </span>
              <div>
                <p class="payment-method-text mb-0">
                  Tarjeta crédito / débito
                </p>
                <span class="payment-method-subtext">
                  Recibimos todas las tarjetas
                </span>
              </div>
              <span class="payment-method-arrow">
                <img
                  v-if="optionSelect == 6"
                  src="@/assets/icons/arrow-method-payment-left.svg"
                  alt="flecha izquierda"/>
                <img
                  v-else
                  src="@/assets/icons/arrow-method-payment-right.svg"
                  alt="flecha derecha"
              /></span>
            </div>
          </div>
        </b-card-header>
        <b-collapse
          v-if="paymentMethods['tarjeta'] && !paymentMethods['tarjeta'].blocked"
          id="accordion-6"
          v-model="isCreditCard"
          accordion="payment-methods"
          role="tabpanel"
          class="content-payment-methods"
        >
          <div class="container-payment-method">
            <payment-credit-card
              v-if="paymentMethods['tarjeta']"
              :is-selected="isCreditCard"
              :data-payment="paymentMethods['tarjeta']"
              :credit-card="!paymentMethods['tarjeta'].blocked"
            />
          </div>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import PaymentEfecty from './methods/PaymentEfecty.vue'
import PaymentPSE from './methods/PaymentPSE.vue'
import PaymentCreditCard from './methods/PaymentCreditCard.vue'
export default {
  name: 'PaymentMethods',
  components: { PaymentEfecty, PaymentPSE, PaymentCreditCard },
  data() {
    return {
      optionSelect: 0,
      isCreditCard: false,
    }
  },
  computed: {
    ...mapState('qec', ['dataQEC']),
    paymentMethods() {
      if (!this.dataQEC) return null
      let paymentMethods = {}
      this.dataQEC.payment_methods.forEach(paymentMethod => {
        paymentMethods[paymentMethod.code] = paymentMethod
      })
      return paymentMethods
    },
  },
  methods: {
    selectCreditCard() {
      this.paymentMethods['tarjeta'] && !this.paymentMethods['tarjeta'].blocked
        ? this.optionSelect == 6
          ? (this.optionSelect = 0)
          : (this.optionSelect = 6)
        : ''
    },
  },
}
</script>
<style>
.checkout-qec-methods {
  max-width: 500px;
  margin: auto;
}
.checkout-qec-methods .edit-correo {
  display: flex;
  justify-content: space-between;
}
.checkout-qec-methods .edit-correo .edit {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}
.checkout-qec-methods .edit-correo .edit:hover {
  font-weight: bold;
}
</style>
