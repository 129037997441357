<template>
  <div v-if="dataQEC && !dataQEC.summary_transaction" class="checkout-qec">
    <div class="checkout-qec-payment-methods">
      <checkout-email v-if="checkEmail" @editEmail="checkEmail = false" />
      <payment-methods v-else @editEmail="checkEmail = true" />
    </div>
    <div class="checkout-qec-summary">
      <summary-order />
    </div>
  </div>
  <Summary v-else-if="dataQEC && dataQEC.summary_transaction" />
</template>
<script>
import { mapActions, mapState } from 'vuex'
import CheckoutEmail from './CheckoutEmail.vue'
import PaymentMethods from './PaymentMethods.vue'
import SummaryOrder from './SummaryOrder.vue'
import Summary from './Summary.vue'
export default {
  name: 'CheckoutQEC',
  components: { CheckoutEmail, PaymentMethods, SummaryOrder, Summary },
  data() {
    return {
      checkEmail: false,
    }
  },
  computed: {
    ...mapState('qec', ['dataQEC']),
  },
  watch: {
    dataQEC: {
      handler: function() {
        if (this.dataQEC) {
          this.checkEmail = this.dataQEC.cart?.client?.verified_email
            ? false
            : true
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$store.state.layouts.footerLayoutDerek = 'footer-simple'
    this.$store.state.layouts.menuLayoutDerek = 'menu-simple'
    this.$store.state.loaderDerek = true
    this.getCartQEC({
      token: this.$route.params.tokenCart,
    }).then(() => {
      this.$store.state.loaderDerek = false
    })
  },
  methods: {
    ...mapActions('qec', ['getCartQEC']),
  },
}
</script>
<style>
.checkout-qec {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  width: 100%;
  justify-content: center;
}
.title-checkot-qec {
  margin-bottom: 1.5rem;
  font-weight: bold;
  font-family: 'PT Sans Bold', sans-serif;
}
.checkout-qec-payment-methods {
  flex-grow: 1;
  flex-basis: 450px;
  max-width: 960px;
  padding: 2rem 1rem;
}
.checkout-qec-summary {
  flex-grow: 1;
  flex-basis: 450px;
  max-width: 960px;
  background-color: #f7f3f0;
}
.checkout-qec .input-form-global {
  width: 100%;
  background: #f7f7f7 !important;
}
.checkout-qec .input-form-global:disabled {
  background-color: #ededec !important;
}
</style>
