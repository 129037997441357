<template>
  <div class="payment-pse">
    <p>
      Selecciona la entidad financiera para continuar con el pago, recuerda que
      debes tener una cuenta habilitada para realizar pagos por internet.
    </p>
    <div class="position-relative mb-4 col-12 col-lg-8 mx-auto mt-4">
      <select v-model="banco" class="payment-input">
        <option value="">Banco</option>
        <option
          v-for="(banco1, index) in bancos"
          :key="index"
          :value="banco1.pseCode"
          >{{
            banco1.description
              .toLowerCase()
              .replace(/^\w/, d => d.toUpperCase())
          }}</option
        >
      </select>
      <span v-if="validateBanco" class="msgError d-block mt-2 text-start">
        Este campo es obligatorio
      </span>
      <span v-if="banco" class="newlatter-error-input">Banco</span>
    </div>
    <button
      class="btn-primary btn-finalize-payment col-12 col-lg-8 mb-3"
      style="min-height: 60px"
      type=""
      @click="validatePSE()"
    >
      Pagar con PSE
    </button>
    <div v-if="errorPaymentPSE" class="text-center mb-3">
      <span class="msgError">{{ errorPaymentPSE }}</span>
    </div>
    <p style="font-size: 12px; display: block">
      Los pagos con este medio se acreditan como máximo en 1 hora
    </p>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  data() {
    return {
      bancos: [],
      banco: '',
      nombreTitular: '',
      tipoDoc: '',
      doc: '',
      tipoPersona: '',
      validateBanco: false,
    }
  },
  computed: {
    ...mapState('qec', ['errorPaymentPSE']),
    /* eslint-disable */
    validateDNI: function() {
      if (this.tipoDoc == 'CC') {
        const re1 = /([0-9]){7}/
        return (
          re1.test(this.doc) && this.doc.length >= 7 && this.doc.length <= 7
        )
      } else if (this.tipoDoc == 'CE') {
        const re2 = /([0-9]){6}/
        return re2.test(this.doc) && this.doc.length == 6
      } else if (this.tipoDoc == 'PAS') {
        const re3 = /([0-9A-Za-z]){5}/
        return re3.test(this.doc) && this.doc.length >= 5
      } else if (this.tipoDoc == 'NIT') {
        return this.doc.length == 9
      }
      return false
    },
  },
  async mounted() {
    await this.axios({
      method: 'GET',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      url: '/payment/payu/get_banks_pse',
    })
      .then(response => {
        this.bancos = response.data.bancos
      })
      .catch(e => {
        console.log(e)
      })
  },
  methods: {
    ...mapActions('qec', ['paymentQECPSE']),
    validations() {
      this.validateBanco = !this.banco
      this.validateName = !(this.nombreTitular.length > 4)
      this.validateTipoDoc = !this.tipoDoc
      this.validateDoc = !this.validateDNI
      this.validateContribuyente = !this.tipoPersona
    },
    async validatePSE() {
      this.validations()
      if (this.banco) {
        this.$store.state.loaderDerek = true
        await this.paymentQECPSE({
          token: this.$route.params.tokenCart,
          payload: {
            doc_financial_institution: this.banco,
          }
        })
          .then(() => {
            this.$store.state.loaderDerek = false
          })
      } else {
        this.validations()
      }
    },
  },
}
</script>