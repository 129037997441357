<template>
  <div>
    <div class="payment-efecty-baloto">
      <p class="mb-4">
        El código para pago se mostrará al finalizar la compra. Ten en cuenta
        que el plazo máximo para realizar el pago en cualquier punto Efecty es
        de <b>24 horas</b>, pasado este plazo tu pedido será cancelado
        automáticamente.* Recuerda que el pago mínimo es de $20.000 pesos.
      </p>
      <div class="d-flex justify-content-center mb-4 col-12 col-lg-8 mx-auto">
        <button
          :disabled="cash < 20000"
          class="btn-primary btn-finalize-payment w-100"
          @click="validatePayment()"
        >
          Continuar con Efecty
        </button>
      </div>
      <div class="text-center mb-3">
        <span v-if="errorMessageEfecty" class="msgError">
          {{ errorMessageEfecty }}
        </span>
      </div>
      <p style="font-size: 12px; display: block">
        No demores en pagar, solo podemos reservarte los productos cuando el
        pago se acredite
      </p>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  props: {
    efecty: {
      type: Boolean,
      default: true,
    },
    cash: {
      type: Number,
      default: 0,
    },
    dataPayment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('qec', ['errorMessageEfecty']),
  },
  methods: {
    ...mapActions('qec', ['paymentQECEfecty']),
    async validatePayment() {
      this.$store.state.loaderDerek = true
      await this.paymentQECEfecty({
        token: this.$route.params.tokenCart,
      }).then(() => {
        this.$store.state.loaderDerek = false
      })
    },
  },
}
</script>
