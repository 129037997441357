<template>
  <div v-if="summaryData" class="summary-qec">
    <div
      class="bg-content-icon-response"
      :class="[
        summaryData.state_pol == 4
          ? 'approved'
          : summaryData.state_pol > 4
          ? 'rejected'
          : 'pending',
      ]"
    >
      <div class="content-icon-response">
        <img
          v-if="summaryData.state_pol == 4"
          src="@/assets/icons/shield-check.svg"
          alt="icon transaction"
        />
        <img
          v-else-if="summaryData.state_pol > 4"
          src="@/assets/icons/shield-exclamation.svg"
          alt="icon transaction"
        />
        <img
          v-else
          src="@/assets/icons/shield-pending.svg"
          alt="icon transaction"
        />
      </div>
    </div>
    <p class="title-checkot-qec">{{ summaryData.title }}</p>
    <p v-if="summaryData.state_pol == 4" class="subtitle-container">
      Se ha registrado tu pago
    </p>
    <p v-else-if="summaryData.state_pol > 4" class="subtitle-container">
      No se ha podido validar tu pago.
    </p>
    <p v-else class="subtitle-container">
      Este proceso puede tomar unos minutos. Te avisaremos en cuanto se
      complete. ¡Gracias por tu paciencia!
    </p>
    <div class="container-summary">
      <p class="description-summary">
        <b>Referencia de pago</b> <br />
        <span>{{ summaryData.reference_payment }}</span>
      </p>
      <p class="description-summary">
        <b>Referencia de la transacción</b><br />
        <span>{{ summaryData.reference_transaction }}</span>
      </p>
      <p class="description-summary">
        <b>Fecha</b><br />
        <span>{{ summaryData.date }}</span>
      </p>
      <p class="description-summary">
        <b>Valor total</b><br />
        <span>{{ summaryData.total }}</span>
      </p>
      <p class="description-summary">
        <b>Descripción</b><br />
        <span>{{ summaryData.description }}</span>
      </p>
      <p class="description-summary">
        <b>Entidad</b><br />
        <span>{{ summaryData.payment_method }}</span>
      </p>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'SummaryQec',
  computed: {
    ...mapState('qec', ['dataQEC']),
    summaryData() {
      return this.dataQEC?.summary_transaction ?? null
    },
  },
}
</script>
<style>
.summary-qec {
  max-width: 450px;
  width: 100%;
  margin: auto;
  padding: 1rem;
}
.summary-qec .bg-content-icon-response {
  margin: 0 auto;
  height: 110px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 1.5rem auto;
}
.summary-qec .bg-content-icon-response .content-icon-response {
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.summary-qec .bg-content-icon-response:after {
  content: '';
  position: absolute;
  height: 80px;
  width: 80px;
  background-color: #ffd9d9;
  border-radius: 50%;
  animation: alternate animationImage 2.5s ease-in-out infinite;
}

.summary-qec .bg-content-icon-response.rejected {
  position: relative;
}
.summary-qec .bg-content-icon-response.rejected:after {
  background-color: #ffd9d9;
}

.summary-qec .bg-content-icon-response.rejected .content-icon-response {
  position: relative;
  z-index: 1;
  background-color: #ff6464;
}

.summary-qec .bg-content-icon-response.approved {
  position: relative;
}
.summary-qec .bg-content-icon-response.approved:after {
  background-color: #c0ece9;
}

.summary-qec .bg-content-icon-response.approved .content-icon-response {
  position: relative;
  z-index: 1;
  background-color: #6dc8c2;
}

.summary-qec .bg-content-icon-response.pending {
  position: relative;
}
.summary-qec .bg-content-icon-response.pending:after {
  background-color: #ffeba3;
}

.summary-qec .bg-content-icon-response.pending .content-icon-response {
  position: relative;
  z-index: 1;
  background-color: #ffd43b;
}

@keyframes animationImage {
  0% {
    transform: scale(1.1);
    opacity: 0.8;
  }

  100% {
    transform: scale(1.5);
    opacity: 1;
  }
}

.summary-qec .icon-status {
  display: flex;
  margin: 1rem auto;
}

.summary-qec .title-checkot-qec {
  text-align: center;
  font-size: 24px;
  margin-bottom: 1rem;
  font-weight: bold;
}

.summary-qec .subtitle-container {
  text-align: center;
  margin-bottom: 2rem;
}

.summary-qec .container-summary {
  background-color: #f5f5f9;
  border-radius: 5px;
  padding: 1rem;
}

.summary-qec .container-summary .description-summary {
  padding-bottom: 1rem;
  border-bottom: 1px solid #e8e8e8;
  text-align: center;
  width: 100%;
}
.summary-qec .container-summary .description-summary:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border: none;
}
</style>
